body {
    background-color: #f0f4fd;
    font-family: 'Arial', sans-serif;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-box {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 320px;
    text-align: center;
}

.login-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.login-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.login-button {
    width: 100%;
    padding: 12px;
    background-color: #2e86de;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;
}

.login-button:hover {
    background-color: #2163b7;
}

.login-error {
    color: red;
    margin-top: 10px;
}