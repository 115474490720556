
.notfoundContainer {
    text-align: center;
    padding: 100px;
    font-family: 'Arial', sans-serif;
  }
  
  .notfoundContainer h1 {
    font-size: 100px;
    color: #2e86de;
  }
  
  .notfoundContainer h2 {
    font-size: 32px;
    color: #333;
  }
  
  .notfoundContainer p {
    font-size: 18px;
    color: #666;
  }
  
  .notfoundContainer a {
    font-size: 18px;
    color: #2e86de;
    text-decoration: none;
    padding: 10px 20px;
    border: 1px solid #2e86de;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .notfoundContainer a:hover {
    background-color: #2e86de;
    color: white;
  }
  